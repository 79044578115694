import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISensorValuesRes } from '../interfaces/sesor-values-res.interface';
import { ISensorLastValuesRes } from '../interfaces/sensor-last-values-res.interface';
import { environment } from '../../environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions'
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SensorsDataService {
 constructor(
  private readonly http: HttpClient, 
  public $fire : AngularFirestore) { 

  }

  public sensors = [];

  start(){
    this.$fire.collection("sensors").valueChanges().subscribe(sens => {
      this.sensors = sens;
      console.log(this.sensors)
    })
  }
  
}
