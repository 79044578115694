import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SensorsDataService } from './services/sensors-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public translate: TranslateService,
    public $data : SensorsDataService
  ) {
    translate.addLangs(['hr', 'en']);

    const lang = localStorage.getItem('lang');
    if (lang) {
      translate.setDefaultLang(lang);
      this.translate.use(lang);
    } else {
      translate.setDefaultLang('hr');
      this.translate.use('hr');
    }

    this.$data.start();
  }

  onCroatianClick() {
    this.translate.use('hr');
    localStorage.setItem('lang', 'hr');
  }

  onEnglishClick() {
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
  }
}
